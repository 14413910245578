import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { SharedService } from "../../../../services/shared.service";

import { AutomationService } from "../../automation.service";
import { TaskSet, Schedule } from "../../automation";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap } from "src/app/models/shared";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-automation-schedules",
    templateUrl: "./automation-schedules.component.html",
    providers: [TimezonePipe]
})
export class AutomationSchedulesComponent implements OnInit, OnDestroy, OnChanges {
    @Input() taskSet: TaskSet;
    @Input() canEdit: boolean;
    @Input() bordered?: boolean;
    @Input() autoRows = true;

    schedules: Schedule[];
    private schedulesBS$ = new BehaviorSubject<Schedule[]>([]);
    private taskSetsSubscription: Subscription;

    tableColumnsSchema: TableSchema<KeyMap<Schedule>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("START_DATE"),
            columnDef: "start_date",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                row => row._frontData.tz_start_time,
                row => row._frontData.tz_start_time,
                () => true
            ),
            sortBy: row => row.start_time
        },
        {
            header: this.translate.instant("END_DATE"),
            columnDef: "end_date",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                row => (row._frontData.tz_end_time ? row._frontData.tz_end_time : "-"),
                row => (row._frontData.tz_end_time ? row._frontData.tz_end_time : "-"),
                () => true
            ),
            sortBy: row => row.end_time
        },
        {
            header: this.translate.instant("NEXT_EXECUTION_TIME"),
            columnDef: "next_execution_time",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                row => (row._frontData.tz_next_execution_time ? row._frontData.tz_next_execution_time : "-"),
                row => (row._frontData.tz_next_execution_time ? row._frontData.tz_next_execution_time : "-"),
                () => true
            ),
            sortBy: row => row.next_execution_time
        },
        {
            header: this.translate.instant("TIME_ZONE"),
            columnDef: "time_zone",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                row => this.timeZonePipe.transform(row.timezone, "utc").text,
                row => this.timeZonePipe.transform(row.timezone, "utc").text,
                () => true
            ),
            sortBy: row => this.timeZonePipe.transform(row.timezone, "utc").text
        },
        {
            header: this.translate.instant("FREQUENCY"),
            columnDef: "frequency",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Schedule>>(
                schedule =>
                    schedule.period_param === 1 &&
                    schedule.period_type !== "minutes" &&
                    schedule.period_type !== "hours" &&
                    schedule.period_type !== "days" &&
                    schedule.period_type !== "weeks" &&
                    schedule.period_type !== "months"
                        ? this.translate.instant(schedule.period_type)
                        : schedule.period_param === 1 && schedule.period_type === "minutes"
                        ? this.translate.instant("EVERY_MINUTE")
                        : schedule.period_param === 1 && schedule.period_type === "hours"
                        ? this.translate.instant("HOURLY")
                        : schedule.period_param === 1 && schedule.period_type === "days"
                        ? this.translate.instant("DAILY")
                        : schedule.period_param === 1 && schedule.period_type === "weeks"
                        ? this.translate.instant("WEEKLY")
                        : schedule.period_param === 1 && schedule.period_type === "months"
                        ? this.translate.instant("MONTHLY")
                        : schedule.period_param !== 1 && schedule.period_type !== "once"
                        ? this.translate.instant("EVERY") + " " + schedule.period_param + " " + schedule.period_type
                        : "",
                schedule =>
                    schedule.period_param === 1 &&
                    schedule.period_type !== "minutes" &&
                    schedule.period_type !== "hours" &&
                    schedule.period_type !== "days" &&
                    schedule.period_type !== "weeks" &&
                    schedule.period_type !== "months"
                        ? this.translate.instant(schedule.period_type)
                        : schedule.period_param === 1 && schedule.period_type === "minutes"
                        ? this.translate.instant("EVERY_MINUTE")
                        : schedule.period_param === 1 && schedule.period_type === "hours"
                        ? this.translate.instant("HOURLY")
                        : schedule.period_param === 1 && schedule.period_type === "days"
                        ? this.translate.instant("DAILY")
                        : schedule.period_param === 1 && schedule.period_type === "weeks"
                        ? this.translate.instant("WEEKLY")
                        : schedule.period_param === 1 && schedule.period_type === "months"
                        ? this.translate.instant("MONTHLY")
                        : schedule.period_param !== 1 && schedule.period_type !== "once"
                        ? this.translate.instant("EVERY") + " " + schedule.period_param + " " + schedule.period_type
                        : "",
                () => true
            )
        },
        {
            header: this.translate.instant("ENABLED"),
            columnDef: "enabled",
            width: 60,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<Schedule>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.is_enabled ? true : false;
            },
            sortBy: (row: Schedule) => (row.is_enabled ? 1 : 0)
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 120,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Schedule, Promise<void>>({
                canEditCallBack: row => this.canEdit,
                canCloneCallBack: row => this.canEdit,
                canDeleteCallBack: row => this.canEdit,
                canEnableCallBack: row => this.canEdit,
                editRef: row => this.editSchedule(row),
                cloneRef: row => this.cloneSchedule(row),
                deleteRef: row => this.deleteSchedule(row),
                enableRef: row => this.toggleEnable(row)
            })
        }
    ];

    constructor(
        private as: AutomationService,
        private modalService: ModalService,
        public sharedService: SharedService,
        private router: Router,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private timeZonePipe: TimezonePipe
    ) {}

    ngOnInit() {
        this.getTableData();
        this.prepTableData();
        this.taskSetsSubscription = this.as.taskSets.subscribe(taskSets => {
            this.taskSet = taskSets.find(ts => ts.id === this.taskSet.id);
            this.schedules = this.taskSet.schedules;
            this.prepTableData();
        });
    }

    ngOnDestroy() {
        this.taskSetsSubscription.unsubscribe();
    }

    getTableData() {
        this.schedules = this.taskSet.schedules;
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.taskSet) {
            // Make sure it's not initial load
            if (changes.taskSet.previousValue && changes.taskSet.currentValue) {
                if (changes.taskSet.previousValue !== changes.taskSet.currentValue) {
                    this.getTableData();
                    this.prepTableData();
                }
            }
        }
    }

    get schedules$() {
        return this.schedulesBS$.asObservable();
    }

    private prepTableData() {
        if (this.schedules) {
            this.schedulesBS$.next(this.schedules);
        }
    }

    addSchedule() {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "schedules", "new"]);
    }

    editSchedule(schedule: Schedule) {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "schedules", schedule.id, "edit"]);
    }

    cloneSchedule(schedule: Schedule) {
        this.router.navigate([Constants.urls.automation, this.taskSet.name, "schedules", schedule.id, "clone"]);
    }

    async toggleEnable(schedule: Schedule) {
        let action = "";
        const model = {
            is_enabled: schedule.is_enabled
        };

        if (schedule.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "SCHEDULE",
            async () => {
                const result = await this.as.updateSchedule(schedule, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " schedule", {
                        id: schedule.id
                    });
                    this.as.refreshTaskSets(true);
                } else {
                    return false;
                }
            },
            schedule.name
        );
    }

    async deleteSchedule(schedule: Schedule) {
        await this.modalService.confirm(
            "DELETE",
            "SCHEDULE",
            async () => {
                const result = await this.as.deleteSchedule(schedule);
                if (result) {
                    this.mixpanelService.sendEvent("delete schedule", {
                        id: schedule.id
                    });
                    this.as.refreshTaskSets(true);
                } else {
                    return false;
                }
            },
            schedule.name
        );
    }
}
