<!-- Details Panel -->
<div class="details-panel" *ngIf="taskSet">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ taskSet.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon>
                </button>
                {{ taskSet.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- View Radio Button -->
            <div class="d-block float-end">
                <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                <mat-button-toggle-group id="viewOptions" name="viewOption" aria-label="view option" [(ngModel)]="viewOption" (change)="viewChange()">
                    <mat-button-toggle value="accordions" title="{{ 'ACCORDION_LIST' | translate }}">
                        <fa-icon icon="th-list" size="sm" flip="horizontal"></fa-icon>
                        <span class="sr-only">{{ "PANELS" | translate }}</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="tabs" title="{{ 'TABS' | translate }}">
                        <fa-icon icon="folder" size="sm"></fa-icon>
                        <span class="sr-only">{{ "TABS" | translate }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary me-2 mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button
                                class="text-secondary"
                                (click)="runTaskSet(taskSet)"
                                title="{{ taskSet.tasks.length > 0 ? ('RUN_TASK_SET' | translate) : ('NO_TASKS_TO_RUN' | translate) }}"
                                [disabled]="taskSet.tasks.length === 0 || saving"
                                mat-menu-item
                            >
                                <fa-icon icon="play" size="xs" [fixedWidth]="true"></fa-icon>
                                {{ "RUN_TASK_SET" | translate
                                }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                            <button (click)="editTaskSet(taskSet.name)" class="text-primary" title="{{ 'EDIT' | translate }}" *ngIf="canEdit" mat-menu-item>
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="cloneTaskSet(taskSet.name)"
                                class="text-primary"
                                mat-menu-item
                                title="{{ 'CLONE' | translate }}"
                                [disabled]="
                                    !resourceTags?.length ||
                                    (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                                "
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button
                                (click)="deleteTaskSet()"
                                class="text-danger"
                                title="{{ taskSet.state !== 'executing' ? ('DELETE' | translate) : ('CANT_DELETE_TASK_SET_THAT_IS_EXECUTING' | translate) }}"
                                [disabled]="taskSet.state === 'executing'"
                                *ngIf="canEdit"
                                mat-menu-item
                            >
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <div class="btn-group me-2 mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        (click)="runTaskSet(taskSet)"
                        title="{{ taskSet.tasks.length > 0 ? ('RUN_TASK_SET' | translate) : ('NO_TASKS_TO_RUN' | translate) }}"
                        [disabled]="taskSet.tasks.length === 0 || saving"
                    >
                        <fa-icon icon="play" size="xs"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "RUN_TASK_SET" | translate }}</span
                        ><span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="editTaskSet(taskSet.name)" title="{{ 'EDIT' | translate }}" *ngIf="canEdit">
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="cloneTaskSet(taskSet.name)"
                        title="{{ 'CLONE' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        (click)="deleteTaskSet()"
                        *ngIf="canEdit"
                        title="{{ taskSet.state !== 'executing' ? ('DELETE' | translate) : ('CANT_DELETE_TASK_SET_THAT_IS_EXECUTING' | translate) }}"
                        [disabled]="taskSet.state === 'executing'"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions" *ngIf="view === 'accordions'">
        <div class="row">
            <div class="col-12 col-xxxl-8">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-xxxl-none">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-automation-details [taskSet]="taskSet" [canEdit]="canEdit"></app-automation-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Tasks -->
                <ngb-accordion #tasksAccordion="ngbAccordion" activeIds="ngb-panel-tasks" class="no-padding">
                    <ngb-panel id="ngb-panel-tasks">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'TASKS' | translate }}">TASKS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="mat-table-accordion-container">
                                <app-automation-tasks [taskSet]="taskSet" [canEdit]="canEdit" [autoRows]="false"></app-automation-tasks>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Schedules -->
                <ngb-accordion #schedulesAccordion="ngbAccordion" activeIds="ngb-panel-schedules" class="no-padding">
                    <ngb-panel id="ngb-panel-schedules">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'SCHEDULES' | translate }}">SCHEDULES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="mat-table-accordion-container">
                                <app-automation-schedules [taskSet]="taskSet" [canEdit]="canEdit" [autoRows]="false"></app-automation-schedules>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Events -->
                <ngb-accordion #eventsAccordion="ngbAccordion" activeIds="ngb-panel-events" class="no-padding">
                    <ngb-panel id="ngb-panel-events">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'EVENTS' | translate }}">EVENTS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-events [objects]="{ task_sets: taskSet }" [id]="taskSet.id" [autoRows]="false"></zx-events>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="col-12 col-xxxl-4">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-none d-xxxl-block">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-automation-details [taskSet]="taskSet" [canEdit]="canEdit"></app-automation-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Notes -->
                <ngb-accordion #notesAccordion="ngbAccordion" activeIds="ngb-panel-notes" *ngIf="taskSet">
                    <ngb-panel id="ngb-panel-notes">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'NOTES' | translate }}">NOTES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-advanced-notes [model]="taskSet" [type]="'task_sets'" [id]="taskSet.id" [canEdit]="canEdit"></zx-advanced-notes>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>

    <!-- Tab Layout -->
    <div class="details-content-tabs" *ngIf="view === 'tabs'">
        <button type="button" class="btn btn-link scroll-left-btn" (click)="scrollLeft()" title="{{ 'SCROLL_LEFT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-left" size="md"></fa-icon>
        </button>
        <button type="button" class="btn btn-link scroll-right-btn" (click)="scrollRight()" title="{{ 'SCROLL_RIGHT' | translate }}" *ngIf="tabsetHasScroll">
            <fa-icon icon="chevron-right" size="md"></fa-icon>
        </button>
        <!-- Tabs -->
        <div class="tabset">
            <ul
                ngbNav
                [destroyOnHide]="false"
                [(activeId)]="activeTab"
                #taskSetTabset="ngbNav"
                [ngClass]="{ hasScroll: tabsetHasScroll }"
                class="justify-content-start nav nav-tabs"
            >
                <li ngbNavItem="details-tab" id="details-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="align-justify" size="sm" title="{{ 'DETAILS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "DETAILS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <!-- Details -->
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <h3 class="d-md-none" title="{{ 'DETAILS' | translate }}">{{ "DETAILS" | translate }}</h3>
                                    <app-automation-details [taskSet]="taskSet" [canEdit]="canEdit"></app-automation-details>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="tasks-tab" id="tasks-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="tasks" size="md" title="{{ 'TASKS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "TASKS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'TASKS' | translate }}">{{ "TASKS" | translate }}</h3>
                            <app-automation-tasks
                                *ngIf="activeTab === 'tasks-tab'"
                                [taskSet]="taskSet"
                                [canEdit]="canEdit"
                                bordered="true"
                            ></app-automation-tasks>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="schedules-tab" id="schedules-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="calendar-alt" size="sm" title="{{ 'SCHEDULES' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "SCHEDULES" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'SCHEDULES' | translate }}">{{ "SCHEDULES" | translate }}</h3>
                            <app-automation-schedules
                                *ngIf="activeTab === 'schedules-tab'"
                                [taskSet]="taskSet"
                                [canEdit]="canEdit"
                                bordered="true"
                            ></app-automation-schedules>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="events-tab" id="events-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="exclamation-triangle" size="sm" title="{{ 'EVENTS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "EVENTS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'EVENTS' | translate }}">{{ "EVENTS" | translate }}</h3>
                            <zx-events [objects]="{ task_sets: taskSet }" [id]="taskSet.id" bordered="true" *ngIf="activeTab === 'events-tab'"></zx-events>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="notes-tab" id="notes-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon [icon]="['far', 'sticky-note']" size="md" title="{{ 'NOTES' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "NOTES" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <h3 class="d-md-none" title="{{ 'NOTES' | translate }}">{{ "NOTES" | translate }}</h3>
                            <form>
                                <div class="form-group">
                                    <zx-advanced-notes [model]="taskSet" [type]="'task_sets'" [id]="taskSet.id" [canEdit]="canEdit"></zx-advanced-notes>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="taskSetTabset"></div>
        </div>
    </div>
</div>
