import { Tag, Source, MediaConnectSource, MediaConnectFlow } from "../../models/shared";
import { Cluster } from "../clusters/cluster";
import { Feeder } from "../zecs/zecs/zec";
import { Receiver } from "../zecs/zecs/zec";
import {
    AdaptiveChannel,
    DeliveryChannel,
    PublishingTarget,
    RtmpPushTarget,
    UdpRtpTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    RistTarget,
    SrtTarget,
    NdiTarget
} from "../channels/channel";

export class TaskSet {
    _frontData?: {
        next_execution_time: string;
        next_execution_timezone: string;
    };
    id: number;
    name: string;
    description: string;
    tasks: Task[];
    resourceTags: Tag[];
    state: string;
    schedules: Schedule[];
    hasFullDetails?: boolean;
}

export class Task {
    id: number;
    task_set_id: number;
    name: string;
    description: string;
    start_offset_sec: number;
    action: string;
    parameter: number;
    state: string;
    created_at: string;
    updated_at: string;
    hasFullDetails?: boolean;
    clusters: Cluster[];
    feeders: Feeder[];
    receivers: Receiver[];
    sources: Source[];
    mediaconnect_sources: MediaConnectSource[];
    adaptive_channels: AdaptiveChannel[];
    delivery_channels: DeliveryChannel[];
    mediaconnect_flows: MediaConnectFlow[];
    publishing_targets: PublishingTarget[];
    rtmp_targets: RtmpPushTarget[];
    udp_rtp_targets: UdpRtpTarget[];
    rist_targets: RistTarget[];
    srt_targets: SrtTarget[];
    ndi_targets: NdiTarget[];
    pull_targets: ZixiPullTarget[];
    push_targets: ZixiPushTarget[];
    objects_by_tags: {
        tag_ids: number[];
        clusters: number;
        feeders: number;
        broadcasters: number;
        receivers: number;
        sources: number;
        mediaconnect_sources: number;
        mediaconnect_flows: number;
        adaptive_channels: number;
        delivery_channels: number;
        publishing_targets: number;
        rtmp_targets: number;
        udp_rtp_targets: number;
        rist_targets: number;
        srt_targets: number;
        ndi_targets: number;
        pull_targets: number;
        push_targets: number;
    };
}

export class Schedule {
    _frontData: {
        tz_start_time: string;
        tz_end_time: string;
        tz_next_execution_time: string;
    };

    id: number;
    name: string;
    task_set_id: number;
    period_type: string;
    period_param: number;
    start_time: string;
    end_time: string;
    timezone: string;
    next_execution_time: string;
    is_enabled: number;
    scheduler_id: string;
}
